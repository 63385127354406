/*! Bulkit | 5.0 | CSS Ninja */

/* ==========================================================================
Bulkit variables 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Base typography
1. Bulkit colors
2. State colors
3. Social colors
4. Predefined gradients
5. Predefined box shadows
=============================================================================
***/

$fullhd-enabled: false;
$body-size: 14px;

/* ==========================================================================
0. Base typography
========================================================================== */

$font-main: "Nexa Bold", sans-serif;
$font-sub: "Nexa Light", sans-serif;
$font-text: "Roboto", sans-serif;

var {
  font-style: normal;
}

p,
ul li {
  font-family: $font-text;
}

i {
  font-style: normal;
}

/* ==========================================================================
1. Bulkit colors
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$grey-white: #f2f2f2;
$grey-white-accent: #e2e2e2;
$command-grey: #fafbfc;
$light-grey: #eff4f7;
$ultra-light-grey: #f7fcff;
$light-white: #fafafa;
$title-grey: #a9abac;
$input-grey: #dbdbdb;
$input-border: #dee2e6;
$blue-grey: #444f60;
$blue-grey-light: lighten($blue-grey, 30%);
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$muted-grey: #999;
$section-grey: #fbfbfb;
$section-grey-accent: #efefef;
$background-grey: #f3f5f7;
$header-grey: #f5f9fc;
$default-input: #ccc;
$placeholder: #cecece;
$fade-grey: #ededed;
$sidebar: #344258;
$coal: #444444;
$grey-6: #666;
$grey-5: #555;
$grey-3: #333;
$med-grey: #6f7592;
$cream-grey: #f6f4ff;
$icon-grey: #818a97;
$border-grey: #d4dadf;
$border-light: #e1e8ee;
$dashboard-grey: #f4f6fb;
$widget-grey: #f5f6fa;
$link-grey: #f5f5f5;

/* ==========================================================================
2. State colors
========================================================================== */

$red: #e62965;
$blue: #039be5;
$green: #06d6a0;
$orange: #eda514;

$info: $blue;
$success: $green;
$warning: $orange;
$danger: $red;

$purple: #8269b2;
$azur: #37c3ff;
$red: #f92b60;
$salmon: #ffa981;
$yellow: #ffd66e;
$grass: #93e088;
$pink: #ff9eb8;
$light-blue: #77d2f3;

/* ==========================================================================
3. Social colors
========================================================================== */

$facebook: #3b5998;
$twitter: #1dcaff;
$linkedin: #007bb6;
$tumblr: #35465c;
$github: #444;
$dribbble: #ea4c89;
$google-plus: #d34836;
$youtube: #cc181e;
$reddit: #ff4500;

/* ==========================================================================
4. Predefined gradients
========================================================================== */

$modal-ga: #6a85b6;
$modal-gb: #bac8e0;

$webkit-modal-gradient: -webkit-linear-gradient(
  45deg,
  $modal-ga 0%,
  $modal-gb 100%
);
$modal-gradient: linear-gradient(45deg, $modal-ga 0%, $modal-gb 100%);

$coal-a: $blue-grey;
$coal-b: #444;

$webkit-coal-gradient: -webkit-linear-gradient(45deg, $coal-a, $coal-b);
$coal-gradient: linear-gradient(45deg, $coal-a, $coal-b);
