.list-block.minimal li {
    border-bottom: unset;
    border-radius: 6px;
}

.is-justify-content-between {
    justify-content: space-between !important;
}

.policy-container {
    ul, li {
        list-style: unset;
        padding: revert;
    }
}
